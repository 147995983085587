@font-face { 
	font-family:'Web Icons';
	src:url(font/web-icons.eot?v=0.2.3);
	src:url(font/web-icons.eot?#iefix&v=0.2.3) format('embedded-opentype'), 
		url(font/web-icons.woff2?v=0.2.3) format('woff2'), 
		url(font/web-icons.woff?v=0.2.3) format('woff'), 
		url(font/web-icons.ttf?v=0.2.3) format('truetype'),
		url(font/web-icons.svg?v=0.2.3#web-icons) format('svg');
	font-weight:400;
	font-style:normal
}
[class*=" wb-"], [class^=wb-] {
	font-family:'Web Icons';
	position:relative;
	display:inline-block;
	font-style:normal;
	font-weight:400;
	text-rendering:auto;
	speak:none;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	-webkit-transform:translate(0,0);
	-ms-transform:translate(0,0);
	-o-transform:translate(0,0);
	transform:translate(0,0)
}
.wb-dashboard:before{
	content:"\f101"
}
.wb-inbox:before {
	content:"\f102"
}
.wb-cloud:before {
	content:"\f103"
}
.wb-bell:before {
	content:"\f104"
}
.wb-book:before {
	content:"\f105"
}
.wb-bookmark:before {
	content:"\f106"
}
.wb-tag:before {
	content:"\f107"
}
.wb-library:before {
	content:"\f108"
}
.wb-share:before {
	content:"\f109"
}
.wb-reply:before {
	content:"\f10a"
}
.wb-refresh:before {
	content:"\f10b"
}
.wb-move:before {
	content:"\f10c"
}
.wb-chat:before {
	content:"\f10d"
}
.wb-chat-working:before {
	content:"\f10e"
}
.wb-chat-text:before {
	content:"\f10f"
}
.wb-chat-group:before {
	content:"\f110"
}
.wb-envelope:before {
	content:"\f111"
}
.wb-envelope-open:before {
	content:"\f112"
}
.wb-user:before {
	content:"\f113"
}
.wb-user-circle:before {
	content:"\f114"
}
.wb-users:before {
	content:"\f115"
}
.wb-user-add:before {
	content:"\f116"
}
.wb-grid-9:before {
	content:"\f117"
}
.wb-grid-4:before {
	content:"\f118"
}
.wb-menu:before {
	content:"\f119"
}
.wb-layout:before {
	content:"\f11a"
}
.wb-fullscreen:before {
	content:"\f11b"
}
.wb-fullscreen-exit:before {
	content:"\f11c"
}
.wb-expand:before {
	content:"\f11d"
}
.wb-contract:before {
	content:"\f11e"
}
.wb-arrow-expand:before {
	content:"\f11f"
}
.wb-arrow-shrink:before {
	content:"\f120"
}
.wb-desktop:before {
	content:"\f121"
}
.wb-mobile:before {
	content:"\f122"
}
.wb-signal:before {
	content:"\f123"
}
.wb-power:before {
	content:"\f124"
}
.wb-more-horizontal:before {
	content:"\f125"
}
.wb-more-vertical:before {
	content:"\f126"
}
.wb-globe:before {
	content:"\f127"
}
.wb-map:before {
	content:"\f128"
}
.wb-flag:before {
	content:"\f129"
}
.wb-pie-chart:before {
	content:"\f12a"
}
.wb-stats-bars:before {
	content:"\f12b"
}
.wb-pluse:before {
	content:"\f12c"
}
.wb-home:before {
	content:"\f12d"
}
.wb-shopping-cart:before {
	content:"\f12e"
}
.wb-payment:before {
	content:"\f12f"
}
.wb-briefcase:before {
	content:"\f130"
}
.wb-search:before {
	content:"\f131"
}
.wb-zoom-in:before {
	content:"\f132"
}
.wb-zoom-out:before {
	content:"\f133"
}
.wb-download:before {
	content:"\f134"
}
.wb-upload:before {
	content:"\f135"
}
.wb-sort-asc:before {
	content:"\f136"
}
.wb-sort-des:before {
	content:"\f137"
}
.wb-graph-up:before {
	content:"\f138"
}
.wb-graph-down:before {
	content:"\f139"
}
.wb-replay:before {
	content:"\f13a"
}
.wb-edit:before {
	content:"\f13b"
}
.wb-pencil:before {
	content:"\f13c"
}
.wb-rubber:before {
	content:"\f13d"
}
.wb-crop:before {
	content:"\f13e"
}
.wb-eye:before {
	content:"\f13f"
}
.wb-eye-close:before {
	content:"\f140"
}
.wb-image:before {
	content:"\f141"
}
.wb-gallery:before {
	content:"\f142"
}
.wb-video:before {
	content:"\f143"
}
.wb-camera:before {
	content:"\f144"
}
.wb-folder:before {
	content:"\f145"
}
.wb-clipboard:before {
	content:"\f146"
}
.wb-order:before {
	content:"\f147"
}
.wb-file:before {
	content:"\f148"
}
.wb-copy:before {
	content:"\f149"
}
.wb-add-file:before {
	content:"\f14a"
}
.wb-print:before {
	content:"\f14b"
}
.wb-calendar:before {
	content:"\f14c"
}
.wb-time:before {
	content:"\f14d"
}
.wb-trash:before {
	content:"\f14e"
}
.wb-plugin:before {
	content:"\f14f"
}
.wb-extension:before {
	content:"\f150"
}
.wb-memory:before {
	content:"\f151"
}
.wb-settings:before {
	content:"\f152"
}
.wb-scissor:before {
	content:"\f153"
}
.wb-wrench:before {
	content:"\f154"
}
.wb-hammer:before {
	content:"\f155"
}
.wb-lock:before {
	content:"\f156"
}
.wb-unlock:before {
	content:"\f157"
}
.wb-volume-low:before {
	content:"\f158"
}
.wb-volume-high:before {
	content:"\f159"
}
.wb-volume-off:before {
	content:"\f15a"
}
.wb-pause:before {
	content:"\f15b"
}
.wb-play:before {
	content:"\f15c"
}
.wb-stop:before {
	content:"\f15d"
}
.wb-musical:before {
	content:"\f15e"
}
.wb-random:before {
	content:"\f15f"
}
.wb-reload:before {
	content:"\f160"
}
.wb-loop:before {
	content:"\f161"
}
.wb-text:before {
	content:"\f162"
}
.wb-bold:before {
	content:"\f163"
}
.wb-italic:before {
	content:"\f164"
}
.wb-underline:before {
	content:"\f165"
}
.wb-format-clear:before {
	content:"\f166"
}
.wb-text-type:before {
	content:"\f167"
}
.wb-table:before {
	content:"\f168"
}
.wb-attach-file:before {
	content:"\f169"
}
.wb-paperclip:before {
	content:"\f16a"
}
.wb-link-intact:before {
	content:"\f16b"
}
.wb-link:before {
	content:"\f16c"
}
.wb-link-broken:before {
	content:"\f16d"
}
.wb-indent-increase:before {
	content:"\f16e"
}
.wb-indent-decrease:before {
	content:"\f16f"
}
.wb-align-justify:before {
	content:"\f170"
}
.wb-align-left:before {
	content:"\f171"
}
.wb-align-center:before {
	content:"\f172"
}
.wb-align-right:before {
	content:"\f173"
}
.wb-list-numbered:before {
	content:"\f174"
}
.wb-list-bulleted:before {
	content:"\f175"
}
.wb-list:before {
	content:"\f176"
}
.wb-emoticon:before {
	content:"\f177"
}
.wb-quote-right:before {
	content:"\f178"
}
.wb-code:before {
	content:"\f179"
}
.wb-code-working:before {
	content:"\f17a"
}
.wb-code-unfold:before {
	content:"\f17b"
}
.wb-chevron-right:before {
	content:"\f17c"
}
.wb-chevron-left:before {
	content:"\f17d"
}
.wb-chevron-left-mini:before {
	content:"\f17e"
}
.wb-chevron-right-mini:before {
	content:"\f17f"
}
.wb-chevron-up:before {
	content:"\f180"
}
.wb-chevron-down:before {
	content:"\f181"
}
.wb-chevron-up-mini:before {
	content:"\f182"
}
.wb-chevron-down-mini:before {
	content:"\f183"
}
.wb-arrow-left:before {
	content:"\f184"
}
.wb-arrow-right:before {
	content:"\f185"
}
.wb-arrow-up:before {
	content:"\f186"
}
.wb-arrow-down:before {
	content:"\f187"
}
.wb-dropdown:before {
	content:"\f188"
}
.wb-dropup:before {
	content:"\f189"
}
.wb-dropright:before {
	content:"\f18a"
}
.wb-dropleft:before {
	content:"\f18b"
}
.wb-sort-vertical:before {
	content:"\f18c"
}
.wb-triangle-left:before {
	content:"\f18d"
}
.wb-triangle-right:before {
	content:"\f18e"
}
.wb-triangle-down:before {
	content:"\f18f"
}
.wb-triangle-up:before {
	content:"\f190"
}
.wb-check-circle:before {
	content:"\f191"
}
.wb-check:before {
	content:"\f192"
}
.wb-check-mini:before {
	content:"\f193"
}
.wb-close:before {
	content:"\f194"
}
.wb-close-mini:before {
	content:"\f195"
}
.wb-plus-circle:before {
	content:"\f196"
}
.wb-plus:before {
	content:"\f197"
}
.wb-minus-circle:before {
	content:"\f198"
}
.wb-minus:before {
	content:"\f199"
}
.wb-alert-circle:before {
	content:"\f19a"
}
.wb-alert:before {
	content:"\f19b"
}
.wb-help-circle:before {
	content:"\f19c"
}
.wb-help:before {
	content:"\f19d"
}
.wb-info-circle:before {
	content:"\f19e"
}
.wb-info:before {
	content:"\f19f"
}
.wb-warning:before {
	content:"\f1a0"
}
.wb-heart:before {
	content:"\f1a1"
}
.wb-heart-outline:before {
	content:"\f1a2"
}
.wb-star:before {
	content:"\f1a3"
}
.wb-star-half:before {
	content:"\f1a4"
}
.wb-star-outline:before {
	content:"\f1a5"
}
.wb-thumb-up:before {
	content:"\f1a6"
}
.wb-thumb-down:before {
	content:"\f1a7"
}
.wb-small-point:before {
	content:"\f1a8"
}
.wb-medium-point:before {
	content:"\f1a9"
}
.wb-large-point:before {
	content:"\f1aa"
}

