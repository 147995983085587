.nestable .dd {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	max-width: 600px;
	list-style: none;
	font-size: 13px;
	line-height: 20px;
}

.nestable .dd-list {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
}

.nestable .dd-list .dd-list {
	padding-left: 30px;
}

.nestable .dd-item, .dd-empty, .nestable .dd-placeholder {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 20px;
	font-size: 13px;
	line-height: 20px;
}

.nestable .dd-item>button {
	position: relative;
	cursor: pointer;
	float: left;
	width: 25px;
	height: 20px;
	margin: 5px 0;
	padding: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	border: 0;
	background: transparent;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	font-weight: bold;
}

.nestable .dd-item>button:before {
	display: block;
	position: absolute;
	width: 100%;
	text-align: center;
	text-indent: 0;
}

.nestable .dd-item>button.dd-expand:before {
	content: '+';
}

.nestable .dd-item>button.dd-collapse:before {
	content: '-';
}

.nestable .dd-expand {
	display: none;
}

.nestable .dd-collapsed .dd-list, .nestable .dd-collapsed .dd-collapse {
	display: none;
}

.nestable .dd-collapsed .dd-expand {
	display: block;
}

.nestable .dd-empty, .dd-placeholder {
	margin: 5px 0;
	padding: 0;
	min-height: 30px;
	background: #f2fbff;
	border: 1px dashed #b6bcbf;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.nestable .dd-empty {
	border: 1px dashed #bbb;
	min-height: 100px;
	background-color: #e5e5e5;
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px;
}

.nestable .dd-dragel {
	position: absolute;
	pointer-events: none;
	z-index: 9999;
}

.nestable .dd-dragel>.dd-item .dd-handle {
	margin-top: 0;
}

.nestable .dd-dragel .dd-handle {
	box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.dd-content {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px 5px 40px;
    color: #333;
    text-decoration: none;
    font-weight: normal;
    border: 1px solid #e7eaec;
    background: #f9f9f9;
    background: -webkit-linear-gradient(top, #f9f9f9 0%, #eee 100%);
    background: -moz-linear-gradient(top, #f9f9f9 0%, #eee 100%);
    background: linear-gradient(top, #f9f9f9 0%, #eee 100%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.nestable .dd-nochildren .dd-placeholder {
	display: none;
}

.nestable .dd-handle:before {
    content: '≡';
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    font-size: 20px;
    font-weight: normal;
}

.nestable .dd-handle:hover {
	color: #2ea8e5;
	background: #fff;
}

.dd-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    height: 30px;
    color: #333;
    text-decoration: none;
    font-weight: normal;
    border: 1px solid #e7eaec;
    background: #f9f9f9;
    background: -webkit-linear-gradient(top, #f9f9f9 0%, #eee 100%);
    background: -moz-linear-gradient(top, #f9f9f9 0%, #eee 100%);
    background: linear-gradient(top, #f9f9f9 0%, #eee 100%);
    border-radius: 3px;
    box-sizing: border-box;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}